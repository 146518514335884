.container {
  background-image: url("/src/images/Background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  /* overflow: hidden; Prevent scrolling */
  /* border: 1px solid red; */
  position: relative; /* Add position: relative to create a stacking context */
  z-index: 1; /* Set a higher z-index for the container */
}

.content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  /* margin-top: -150px; */
  text-align: center;
  /* border: 1px solid red; */
  position: relative; /* Add position: relative to create a stacking context */
  z-index: 5; /* Set a higher z-index for the container */
}
.textures-content {
  background-color: rgba(255, 255, 255, 0.8);

  padding: 40px;
  border-radius: 10px;
  max-width: 1200px;
  min-width: 1200px;
  /* margin-top: -150px; */
  text-align: center;
  /* border: 1px solid red; */
  position: relative; /* Add position: relative to create a stacking context */
  z-index: 5; /* Set a higher z-index for the container */
}

.content h1 {
  color: #000;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.content h2 {
  color: orange;
  font-size: 24px;
  margin-bottom: 20px;
}
.content p {
  color: orange;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.tutorial-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 900px;
  margin: 0 auto;
}

.tutorial-card {
  width: calc(33.33% - 20px);
  margin-bottom: 20px;
  position: relative;
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid black;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  object-fit: cover;
  transition: opacity 0.3s ease-in-out;
}

.image-wrapper h3 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  padding: 10px;
  font-size: 18px;
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.tutorial-card:hover .image-wrapper img {
  opacity: 0.7;
}

.tutorial-card:hover .image-wrapper h3 {
  opacity: 0;
}

.form-group {
  margin-bottom: 20px;
}
form {
  max-width: 400px;
  margin: 0 auto;
}

label {
  color: #000;
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  text-align: left;
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

button[type="submit"] {
  background-color: orange;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

button[type="submit"]:hover {
  color: black;
}
/* header */
.header {
  background-color: #000;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo {
  margin-right: 20px;
}

.logo img {
  height: 55px;
}

.nav-links {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links li:first-child {
  margin-left: 0;
}

.nav-links li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 20px;
}

.nav-links li a:hover {
  color: orange;
}

.nav-links li a.active {
  color: orange;
  font-weight: bold;
}
/* Footer */
.footer {
  background-color: #000;
  color: #fff;
  padding: 15px;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 3; /* Set a higher z-index to ensure it's above other elements */
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-links,
.footer-info {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-links a,
.footer-info a {
  color: #fff;
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s;
}

.footer-links a:hover,
.footer-info a:hover {
  color: orange;
}

.footer-social-links {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.footer-social-links a {
  display: inline-block;
  margin-left: 10px;
  transition: opacity 0.3s;
}

.footer-social-links a:hover {
  opacity: 0.7;
}

.home-title h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.home-search {
  margin-bottom: 2rem;
}

.home-search-button {
  padding: 1rem 2rem;
  background-color: orange;
  color: #fff;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}
.home-search-button:hover {
  color: black;
}

.image-container {
  width: 100%; /* Adjust to take up the full width */
  max-width: 800px;
  margin: 0 auto; /* Center the image container */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.about-title {
  font-size: 36px;
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

.about-description {
  font-size: 18px;
  color: #000;
  line-height: 1.5;
  margin-bottom: 20px;
}

/* textures */

/* .textures-container {
  background-image: url("/src/images/Background.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 20px;
} */
/* .textures-content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 40px;
  border-radius: 10px;
  max-width: 800px;
  margin-top: -150px;
  text-align: center;
  display: flex;
  flex-direction: row;
  border: 1px solid red;
  position: relative; /* Add position: relative to create a stacking context */

.filter-gallery-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.filter-menu {
  width: 15%;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.filter-menu h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.filter-menu ul {
  list-style: none;
  padding: 0;
}

.filter-menu li {
  font-size: 16px;
  margin-bottom: 5px;
  cursor: pointer;
}
.filter-menu button {
  background-color: orange;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  width: 120px;
}

.filter-menu button:hover {
  background-color: #f0f0f0; /* Change background color on hover */
  color: black;
}

.image-gallery {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px;
  padding-left: 5px;
  background-color: rgb(73, 70, 70);
  border-radius: 5px;
}

.folder-container {
  width: calc(27% - 20px);
  padding: 10px;
  border: 1px solid #ccc;
  background-color: black;
  color: white;
  max-height: 250px;
}

.carousel-image {
  max-height: 300px; /* Adjust the maximum height */
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.carousel-image img {
  max-width: 80%;
  max-height: 100%;
  object-fit: cover;

  border-radius: 100%;
}
.folder-name {
  margin-top: 5px;
  text-align: center;
}

/* Loading */
.loading-message {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-message p {
  font-size: 1.5rem; /* Adjust the font size to your liking */
  margin-bottom: 10px;
  color: #ccc;
}

.loading-spinner {
  border: 8px solid #f3f3f3; /* Light gray border */
  border-top: 8px solid orange; /* Orange border for animation */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite; /* Add a simple rotation animation */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.terms-body {
  color: gray;
  text-align: left;
}
.terms-body p {
  color: gray;
  text-align: left;
  font-size: medium;
}

.faqs-answer p {
  color: gray;
  text-align: left;
  font-size: medium;
}
.faq-body h2 {
  text-align: left;
  font-size: medium;
}
.faq-body p {
  color: gray;
  text-align: left;
  font-size: medium;
}
